import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import { MDXRenderer } from "gatsby-plugin-mdx"
import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function PagePost({ data }) {
  const post = data.mdx
  const {
    genel: {
      kullanimKosullari,
      gizlilikPolitikasi,
      mesafeliSatisSozlesmesi,
      iadeveTeslimatSartlari,
    },
  } = useTranslations()
  const { slug, type } = post.frontmatter
  return (
    <Layout>
      {type == "legal" && (
        <div
          className="nav flex-column nav-pills position-sticky float-right mr-5 d-none d-lg-block"
          id="v-pills-tab"
          style={{ width: 200, top: 100 }}
          aria-orientation="vertical"
        >
          <LocalizedLink
            className={`nav-link ${
              slug === "/kullanim-kosullari/" ? "active" : ""
            }`}
            to="/kullanim-kosullari/"
          >
            {kullanimKosullari}
          </LocalizedLink>
          <LocalizedLink
            className={`nav-link ${
              slug === "/gizlilik-politikasi/" ? "active" : ""
            }`}
            to="/gizlilik-politikasi/"
          >
            {gizlilikPolitikasi}
          </LocalizedLink>
          <LocalizedLink
            className={`nav-link ${
              slug === "/mesafeli-satis-sozlesmesi/" ? "active" : ""
            }`}
            to="/mesafeli-satis-sozlesmesi/"
          >
            {mesafeliSatisSozlesmesi}
          </LocalizedLink>
          <LocalizedLink
            className={`nav-link ${
              slug === "/iade-teslimat-sartlari/" ? "active" : ""
            }`}
            to="/iade-teslimat-sartlari/"
          >
            {iadeveTeslimatSartlari}
          </LocalizedLink>
        </div>
      )}
      <Container
        style={{
          maxWidth: post.frontmatter.type == "legal" ? 700 : 1200,
          marginBottom: 100,
        }}
      >
        <div>
          <h1
            className="text-center font-weight-bold"
            style={{ fontSize: 32, marginTop: 60, marginBottom: 40 }}
          >
            {post.frontmatter.title}
          </h1>
          <div className="text-justify">
            <MDXRenderer className="text-justify">{post.body}</MDXRenderer>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($locale: String!, $slug: String!) {
    mdx(
      frontmatter: { slug: { eq: $slug } }
      fields: { locale: { eq: $locale } }
    ) {
      body
      frontmatter {
        title
        slug
        type
        date(formatString: "DD MMMM yyyy, dddd", locale: "tr")
      }
    }
  }
`
PagePost.propTypes = {
  data: PropTypes.object,
}
